import { WppModal, WppButton, WppIconWarning } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import styles from 'components/common/warningConfirmModal/WarningConfirmationModal.module.scss'

interface Props {
  open: boolean
  title: string
  bodyText: string
  onClose: () => void
  onConfirm: () => void
}

export const WarningConfirmationModal = ({ open, title, bodyText, onClose, onConfirm }: Props) => {
  const { t } = useTranslation()

  return (
    <WppModal open={open} size="s">
      <h3 slot="header">
        <Flex align="center">
          <WppIconWarning />
          <span className={styles.modalTitle}>{title}</span>
        </Flex>
      </h3>
      <p slot="body">{bodyText}</p>
      <div slot="actions">
        <Flex justify="end" gap={8}>
          <WppButton variant="secondary" size="s" onClick={onClose}>
            {t('common.cancel')}
          </WppButton>
          <WppButton variant="destructive" size="s" onClick={onConfirm}>
            {t('common.remove')}
          </WppButton>
        </Flex>
      </div>
    </WppModal>
  )
}

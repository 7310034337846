// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cPn5q{width:100%;margin-bottom:12px;padding:12px 8px;background-color:var(--wpp-primary-color-100);border-radius:var(--wpp-border-radius-m)}.rU8lT{white-space:pre-line}.vbTAC{flex-shrink:0}", "",{"version":3,"sources":["webpack://./src/components/assistant/chat/chatBubble/question/Question.module.scss"],"names":[],"mappings":"AAAA,OACE,UAAA,CACA,kBAAA,CACA,gBAAA,CACA,6CAAA,CACA,wCAAA,CAGF,OACE,oBAAA,CAGF,OACE,aAAA","sourcesContent":[".chatBubbleQuestion {\n  width: 100%;\n  margin-bottom: 12px;\n  padding: 12px 8px;\n  background-color: var(--wpp-primary-color-100);\n  border-radius: var(--wpp-border-radius-m);\n}\n\n.text {\n  white-space: pre-line;\n}\n\n.avatar {\n  flex-shrink: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chatBubbleQuestion": "cPn5q",
	"text": "rU8lT",
	"avatar": "vbTAC"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".r_49n{width:100%;margin-bottom:12px;padding:12px 8px;background-color:var(--wpp-grey-color-200);border-radius:var(--wpp-border-radius-m)}.r2pW3{white-space:pre-line}.vEEPp{color:var(--wpp-warning-color-500)}", "",{"version":3,"sources":["webpack://./src/components/assistant/chat/chatBubble/openAIAnswer/OpenAIAnswer.module.scss"],"names":[],"mappings":"AAAA,OACE,UAAA,CACA,kBAAA,CACA,gBAAA,CACA,0CAAA,CACA,wCAAA,CAGF,OACE,oBAAA,CAGF,OACE,kCAAA","sourcesContent":[".chatBubbleAnswer {\n  width: 100%;\n  margin-bottom: 12px;\n  padding: 12px 8px;\n  background-color: var(--wpp-grey-color-200);\n  border-radius: var(--wpp-border-radius-m);\n}\n\n.text {\n  white-space: pre-line;\n}\n\n.errorText {\n  color: var(--wpp-warning-color-500);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chatBubbleAnswer": "r_49n",
	"text": "r2pW3",
	"errorText": "vEEPp"
};
export default ___CSS_LOADER_EXPORT___;

import { assistantApi } from 'api'
import { CancelableRequestProducer } from 'api/common/types'
import { ConversationMessageDto } from 'types/dto/ConversationDto'

export interface Props {
  conversationId: string
  question: string
}

export const createQuestion = ({
  conversationId,
  question,
}: Props): CancelableRequestProducer<ConversationMessageDto | ConversationMessageDto[]> =>
  assistantApi.post(`/chats/${conversationId}/messages`, { content: question })

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BwqlL{position:fixed;top:63px;left:0;width:100%;height:calc(100% - 63px);pointer-events:none}.s8wQn{pointer-events:all}.gh8nK{padding:20px 14px 16px 24px}.Ujotd{width:100%;height:100%;background-color:var(--wpp-grey-color-000);border-radius:var(--wpp-border-radius-l);box-shadow:0px 8px 32px 0px rgba(52,58,63,.25)}", "",{"version":3,"sources":["webpack://./src/components/assistant/assistantPopover/AssistantPopover.module.scss"],"names":[],"mappings":"AAAA,OACE,cAAA,CACA,QAAA,CACA,MAAA,CACA,UAAA,CACA,wBAAA,CACA,mBAAA,CAGF,OACE,kBAAA,CAGF,OACE,2BAAA,CAGF,OACE,UAAA,CACA,WAAA,CACA,0CAAA,CACA,wCAAA,CACA,8CAAA","sourcesContent":[".dragSurface {\n  position: fixed;\n  top: 63px; // TODO use core-app header height variable (if possible)\n  left: 0;\n  width: 100%;\n  height: calc(100% - 63px);\n  pointer-events: none;\n}\n\n.assistantRnd {\n  pointer-events: all;\n}\n\n.header {\n  padding: 20px 14px 16px 24px;\n}\n\n.assistant {\n  width: 100%;\n  height: 100%;\n  background-color: var(--wpp-grey-color-000);\n  border-radius: var(--wpp-border-radius-l);\n  box-shadow: 0px 8px 32px 0px rgba(52, 58, 63, 0.25);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dragSurface": "BwqlL",
	"assistantRnd": "s8wQn",
	"header": "gh8nK",
	"assistant": "Ujotd"
};
export default ___CSS_LOADER_EXPORT___;

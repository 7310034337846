import { WppDivider, WppActionButton, WppIconGear } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import styles from 'components/assistant/assistantPopover/assistantContent/footer/Footer.module.scss'

interface Props {
  onOpenAssistantSettings: () => void
}

export const Footer = ({ onOpenAssistantSettings }: Props) => {
  const { t } = useTranslation()

  return (
    <div className={styles.footer}>
      <WppDivider />
      <div className={styles.footerContent}>
        <WppActionButton onClick={onOpenAssistantSettings}>
          <WppIconGear slot="icon-start" />
          {t('common.settings')}
        </WppActionButton>
      </div>
    </div>
  )
}

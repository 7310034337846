import { WppSkeleton, WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import styles from 'components/assistant/savedConversationsList/SavedConversationsList.module.scss'
import { SavedConversationsListItem } from 'components/assistant/savedConversationsList/savedConversationsListItem/SavedConversationsListItem'
import { useAssistant } from 'hooks/useAssistant'
import { ConversationDto } from 'types/dto/ConversationDto'

interface Props {
  onSelectConversation: (conversation: ConversationDto) => void
}

export const SavedConversationsList = ({ onSelectConversation }: Props) => {
  const { t } = useTranslation()
  const { savedConversations, isLoadingSavedConversations } = useAssistant()

  if (isLoadingSavedConversations) {
    return (
      <div className={styles.savedConversationsList}>
        {[...Array(6)].map((e, i) => (
          <WppSkeleton
            variant="rectangle"
            width="100%"
            height="36"
            className={styles.skeletonListItemPlaceholder}
            key={i}
          />
        ))}
      </div>
    )
  }

  if (savedConversations.length === 0) {
    return (
      <div className={styles.savedConversationsList}>
        <div className={styles.emptyListTextContainer}>
          <WppTypography tag="p" type="s-body">
            {t('empty_saved_conversations')}
          </WppTypography>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.savedConversationsList}>
      {savedConversations.map(conversation => (
        <SavedConversationsListItem
          conversation={conversation}
          onSelectConversation={onSelectConversation}
          key={conversation.id}
        />
      ))}
    </div>
  )
}

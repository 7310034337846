import { assistantApi } from 'api'

export interface Props {
  conversationId: string
  name?: string
  saved: boolean
}

export const patchConversation = ({ conversationId, ...body }: Props) =>
  assistantApi.patch(`/chats/${conversationId}`, body)

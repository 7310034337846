import { OpenApiAnswerDto } from 'types/dto/ConversationDto'

const AUDIENCE_PROMP = '@audience'

export const audiencePrompAnswer = (question: string, chatId: string): OpenApiAnswerDto => {
  if (question.includes('main') && question.includes('customer') && question.includes('segments')) {
    return transformToPrompt(
      "1. Health-focused Pet Owners: This segment includes pet owners who prioritize their pets' health and well-being, seeking high-quality, nutritious food options. They are willing to invest in premium products that cater to their pets' specific dietary needs and life stages.\n\n" +
        '2. Value-oriented Consumers: This segment consists of pet owners who seek a balance between quality and affordability when purchasing pet food and care products. They may opt for brands which offer reliable nutrition at a more budget-friendly price point.\n\n' +
        '3. Specialized Pet Needs: This segment comprises pet owners whose pets have specific dietary requirements or preferences, such as grain-free, weight management, or breed-specific formulas. Tailor solutions to this segment through product lines that cater to these unique needs.\n\n' +
        '4. Eco-conscious Pet Owners: This segment includes environmentally aware pet owners who value sustainability and ethical practices in the pet food industry. They may be drawn to brands that use responsibly sourced ingredients and reduce packaging waste, aligning with their values.\n\n' +
        '5. Professionals and Breeders: This segment consists of veterinarians, pet professionals, and breeders who recommend or sell pet food and care products to their clients or require specialized diets for their animals. They trust reputation for quality and research-backed nutrition.\n\n',
      chatId,
    )
  }
  if (question.includes('population') && question.includes('percentage')) {
    return transformToPrompt(
      'Can you give me an estimate for the population percentage covered by each segment?\n\n' +
        '1. Health-focused Pet Owners: 35%, owning approximately 8.4 million pets.\n' +
        '2. Value-oriented Consumers: 30%, owning approximately 7.2 million pets.\n' +
        '3. Specialized Pet Needs: 15%, owning approximately 3.6 million pets.\n' +
        '4.Eco-conscious Pet Owners: 38%, owning approximately 9.12 million pets.\n' +
        '5. Professionals and Breeders: 5%, owning approximately 1.2 million pets.\n\n' +
        'Please note that these percentages are not mutually exclusive, as some pet owners may belong to multiple segments.',
      chatId,
    )
  }
  if (question.includes('brief') && (question.includes('images') || question.includes('assets'))) {
    return transformToPrompt(
      'Images of health-focused pet owners that are serving a premium product to their cat in a home that shows prioritisation of sustainability.\n\n Use the the brief above for Content matrix in Imagine.',
      chatId,
    )
  }
  return transformToPrompt(
    "Apologies, but it seems there is no data available for your query at the moment. If you have any other questions or need assistance with something else, please feel free to ask, and I'll be happy to help! ",
    chatId,
  )
}

const transformToPrompt = (answer: string, chatId: string): OpenApiAnswerDto => {
  return {
    id: new Date().toDateString(),
    role: 'assistant',
    type: 'OPEN_API',
    chatId,
    createdAt: new Date(),
    updatedAt: new Date(),
    content: answer,
  }
}

export const isAudiencePromp = (question: string) => {
  return question.includes(AUDIENCE_PROMP)
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JOKue{padding-left:8px}", "",{"version":3,"sources":["webpack://./src/components/common/warningConfirmModal/WarningConfirmationModal.module.scss"],"names":[],"mappings":"AAAA,OACE,gBAAA","sourcesContent":[".modalTitle {\n  padding-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalTitle": "JOKue"
};
export default ___CSS_LOADER_EXPORT___;

import { WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import styles from 'components/assistant/chat/answerLoadingPlaceholder/AnswerLoadingPlaceholder.module.scss'
import { ReactComponent as BotIllustration } from 'components/assistant/chat/answerLoadingPlaceholder/assets/BotIllustration.svg'
import { Flex } from 'components/common/flex/Flex'

export const AnswerLoadingPlaceholder = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.card}>
      <Flex direction="column" gap={12}>
        <BotIllustration className={styles.illustration} />
        <WppTypography type="s-strong" className={styles.text}>
          {t('answer_loading')}
        </WppTypography>
      </Flex>
    </div>
  )
}

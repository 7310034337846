// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".svEXi{margin-bottom:24px;padding:13px 8px;background-color:var(--wpp-grey-color-700);box-shadow:var(--wpp-box-shadow-s)}._seUO{color:var(--wpp-grey-color-000)}.EjkD6{display:grid;grid-template-columns:repeat(2, 1fr);grid-template-rows:repeat(2, 1fr);grid-column-gap:24px;grid-row-gap:24px}.gnBZW{padding:12px 14px;background-color:var(--wpp-primary-color-100);border-radius:var(--wpp-border-radius-m)}", "",{"version":3,"sources":["webpack://./src/components/assistant/chat/chatInstructions/ChatInstructions.module.scss"],"names":[],"mappings":"AAAA,OACE,kBAAA,CACA,gBAAA,CACA,0CAAA,CACA,kCAAA,CAGF,OACE,+BAAA,CAGF,OACE,YAAA,CACA,oCAAA,CACA,iCAAA,CACA,oBAAA,CACA,iBAAA,CAGF,OACE,iBAAA,CACA,6CAAA,CACA,wCAAA","sourcesContent":[".mentionsInstructionsCard {\n  margin-bottom: 24px;\n  padding: 13px 8px;\n  background-color: var(--wpp-grey-color-700);\n  box-shadow: var(--wpp-box-shadow-s);\n}\n\n.mentionsInstructions {\n  color: var(--wpp-grey-color-000);\n}\n\n.exampleCardsGrid {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  grid-template-rows: repeat(2, 1fr);\n  grid-column-gap: 24px;\n  grid-row-gap: 24px;\n}\n\n.exampleCard {\n  padding: 12px 14px;\n  background-color: var(--wpp-primary-color-100);\n  border-radius: var(--wpp-border-radius-m);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mentionsInstructionsCard": "svEXi",
	"mentionsInstructions": "_seUO",
	"exampleCardsGrid": "EjkD6",
	"exampleCard": "gnBZW"
};
export default ___CSS_LOADER_EXPORT___;

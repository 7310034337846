import { assistantApi } from 'api'
import { CancelableRequestProducer } from 'api/common/types'
import { CreateConnectionDetailsDto, ConnectionDetailsDto } from 'types/dto/AssistantConfigDto'

export const createConnectionDetails = ({
  resourceName,
  apiKey,
  modelName,
  tokensLimit,
  tenantId,
}: CreateConnectionDetailsDto): CancelableRequestProducer<ConnectionDetailsDto> =>
  assistantApi.post('/connection_details', {
    resourceName,
    apiKey,
    modelName,
    tokensLimit,
    tenantId,
  })

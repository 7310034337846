import { WppActionButton, WppTypography, WppIconTrash } from '@platform-ui-kit/components-library-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import styles from 'components/assistant/savedConversationsList/savedConversationsListItem/SavedConversationsListItem.module.scss'
import { Flex } from 'components/common/flex/Flex'
import { WarningConfirmationModal } from 'components/common/warningConfirmModal/WarningConfirmationModal'
import { useAssistant } from 'hooks/useAssistant'
import { ConversationDto } from 'types/dto/ConversationDto'

interface Props {
  conversation: ConversationDto
  onSelectConversation: (conversation: ConversationDto) => void
}

export const SavedConversationsListItem = ({ conversation, onSelectConversation }: Props) => {
  const { t } = useTranslation()
  const { deleteConversation } = useAssistant()

  const [isDeleteConversationModalOpen, setIsDeleteConversationModalOpen] = useState(false)

  const handleConversationClick = () => {
    onSelectConversation(conversation)
  }

  const handleConfirmDeleteConversation = () => {
    deleteConversation({ conversationId: conversation.id })
    setIsDeleteConversationModalOpen(false)
  }

  return (
    <>
      <button className={styles.conversationItemButton} onClick={handleConversationClick}>
        <Flex direction="row" justify="between" gap={8}>
          <WppTypography tag="span" type="s-body" className={styles.conversationItemText}>
            {conversation.name}
          </WppTypography>
          <WppActionButton
            onClick={e => {
              e.stopPropagation()
              setIsDeleteConversationModalOpen(true)
            }}
            className={styles.deleteConversationButton}
          >
            <WppIconTrash size="s" color="var(--wpp-grey-color-600)" />
          </WppActionButton>
        </Flex>
      </button>
      <WarningConfirmationModal
        open={isDeleteConversationModalOpen}
        title={t('delete_conversation_alert.title')}
        bodyText={t('delete_conversation_alert.description')}
        onClose={() => setIsDeleteConversationModalOpen(false)}
        onConfirm={handleConfirmDeleteConversation}
      />
    </>
  )
}

import { WppTypography, WppIconBot } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import ReactMarkdown from 'react-markdown'

import styles from 'components/assistant/chat/chatBubble/openAIAnswer/OpenAIAnswer.module.scss'
import { Flex } from 'components/common/flex/Flex'

interface Props {
  text: string
  isError?: boolean
}

export const OpenAIAnswer = ({ text, isError }: Props) => {
  return (
    <Flex className={styles.chatBubbleAnswer} direction="row" wrap="nowrap" gap={12}>
      <WppIconBot
        height={32}
        width={32}
        color={isError ? 'var(--wpp-warning-color-500)' : 'var(--wpp-dataviz-color-seq-grey-600)'}
      />
      <WppTypography tag="p" type="s-body" className={clsx(styles.text, isError && styles.errorText)}>
        <ReactMarkdown>{text}</ReactMarkdown>
      </WppTypography>
    </Flex>
  )
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".x7Rxe{display:inline}._RUyE{display:inline}.dKtfm{color:var(--wpp-primary-color-500);text-decoration:underline}.dKtfm:hover{color:var(--wpp-primary-color-600)}", "",{"version":3,"sources":["webpack://./src/components/assistant/chat/chatBottomText/ChatBottomText.module.scss"],"names":[],"mappings":"AAAA,OACE,cAAA,CAGF,OACE,cAAA,CAGF,OACE,kCAAA,CACA,yBAAA,CAEA,aACE,kCAAA","sourcesContent":[".tokenLimitReachedText {\n  display: inline;\n}\n\n.clearConversationButton {\n  display: inline;\n}\n\n.clearConversationButtonText {\n  color: var(--wpp-primary-color-500);\n  text-decoration: underline;\n\n  &:hover {\n    color: var(--wpp-primary-color-600);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tokenLimitReachedText": "x7Rxe",
	"clearConversationButton": "_RUyE",
	"clearConversationButtonText": "dKtfm"
};
export default ___CSS_LOADER_EXPORT___;

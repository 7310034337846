// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pEpa6{visibility:hidden}.sFGup{width:100%;margin-bottom:16px;padding:5px 8px 5px 12px;border-radius:var(--wpp-border-radius-m)}.sFGup:hover{background-color:var(--wpp-grey-color-300)}.sFGup:hover .pEpa6{visibility:visible}.wAPVy{min-height:26px;padding-top:2px;overflow:hidden;white-space:nowrap;text-align:left;text-overflow:ellipsis}", "",{"version":3,"sources":["webpack://./src/components/assistant/savedConversationsList/savedConversationsListItem/SavedConversationsListItem.module.scss"],"names":[],"mappings":"AAAA,OACE,iBAAA,CAGF,OACE,UAAA,CACA,kBAAA,CACA,wBAAA,CACA,wCAAA,CAEA,aACE,0CAAA,CAEA,oBACE,kBAAA,CAKN,OACE,eAAA,CACA,eAAA,CACA,eAAA,CACA,kBAAA,CACA,eAAA,CACA,sBAAA","sourcesContent":[".deleteConversationButton {\n  visibility: hidden;\n}\n\n.conversationItemButton {\n  width: 100%;\n  margin-bottom: 16px;\n  padding: 5px 8px 5px 12px;\n  border-radius: var(--wpp-border-radius-m);\n\n  &:hover {\n    background-color: var(--wpp-grey-color-300);\n\n    & .deleteConversationButton {\n      visibility: visible;\n    }\n  }\n}\n\n.conversationItemText {\n  min-height: 26px;\n  padding-top: 2px;\n  overflow: hidden;\n  white-space: nowrap;\n  text-align: left;\n  text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"deleteConversationButton": "pEpa6",
	"conversationItemButton": "sFGup",
	"conversationItemText": "wAPVy"
};
export default ___CSS_LOADER_EXPORT___;

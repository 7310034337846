// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zJ9Gt{padding-top:56px}.EAIse{margin-bottom:16px}.CvbW5{padding:12px 16px;background-color:var(--wpp-primary-color-100);border-radius:var(--wpp-border-radius-m)}", "",{"version":3,"sources":["webpack://./src/components/assistant/savedConversationsList/SavedConversationsList.module.scss"],"names":[],"mappings":"AAAA,OACE,gBAAA,CAGF,OACE,kBAAA,CAGF,OACE,iBAAA,CACA,6CAAA,CACA,wCAAA","sourcesContent":[".savedConversationsList {\n  padding-top: 56px;\n}\n\n.skeletonListItemPlaceholder {\n  margin-bottom: 16px;\n}\n\n.emptyListTextContainer {\n  padding: 12px 16px;\n  background-color: var(--wpp-primary-color-100);\n  border-radius: var(--wpp-border-radius-m);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"savedConversationsList": "zJ9Gt",
	"skeletonListItemPlaceholder": "EAIse",
	"emptyListTextContainer": "CvbW5"
};
export default ___CSS_LOADER_EXPORT___;

import { WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import styles from 'components/assistant/chat/chatInstructions/ChatInstructions.module.scss'

export const ChatInstructions = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className={styles.mentionsInstructionsCard}>
        <WppTypography type="s-body" tag="p" className={styles.mentionsInstructions}>
          {t('how_to_use.mentions_instructions')}
        </WppTypography>
      </div>
      <div className={styles.exampleCardsGrid}>
        <div className={styles.exampleCard}>
          <WppTypography type="s-body" tag="p">
            {t('how_to_use.example_1')}
          </WppTypography>
        </div>
        <div className={styles.exampleCard}>
          <WppTypography type="s-body" tag="p">
            {t('how_to_use.example_2')}
          </WppTypography>
        </div>
        <div className={styles.exampleCard}>
          <WppTypography type="s-body" tag="p">
            {t('how_to_use.example_3')}
          </WppTypography>
        </div>
        <div className={styles.exampleCard}>
          <WppTypography type="s-body" tag="p">
            {t('how_to_use.example_4')}
          </WppTypography>
        </div>
      </div>
    </>
  )
}

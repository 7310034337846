import {
  WppButton,
  WppTypography,
  WppInput,
  WppTooltip,
  WppIconInfo,
  WppSelect,
  WppListItem,
  WppSpinner,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useState, useEffect, useCallback, FormEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

import styles from 'components/assistant/assistantPopover/assistantSettings/AssistantSettings.module.scss'
import { Flex } from 'components/common/flex/Flex'
import { useAssistantConfigurator } from 'hooks/useAssistantConfigurator'
import { validationSchemaAdapter } from 'utils/formValidation'

const tooltipIconColor = 'var(--wpp-grey-color-800)'

const conversationCharacterLimitOptions = [8192, 32768]

interface Props {
  onCloseAssistantSettings: () => void
}

export const AssistantSettings = ({ onCloseAssistantSettings }: Props) => {
  const { t } = useTranslation()
  const {
    connectionDetails,
    isConnectionDetailsLoading,
    createConnectionDetails,
    isCreateConnectionDetailsLoading,
    updateConnectionDetails,
    isUpdateConnectionDetailsLoading,
  } = useAssistantConfigurator()

  const [form, setForm] = useState({
    resourceName: '',
    apiKey: '',
    modelName: '',
    tokensLimit: conversationCharacterLimitOptions[0],
  })
  const [formErrors, setFormErrors] = useState({
    resourceName: { status: false, message: '' },
    apiKey: { status: false, message: '' },
    modelName: { status: false, message: '' },
    tokensLimit: { status: false, message: '' },
  })
  const [connectionDetailsIdToUpdate, setConnectionDetailsIdToUpdate] = useState<null | string>(null)

  useEffect(() => {
    if (connectionDetails && !connectionDetailsIdToUpdate) {
      setForm({
        resourceName: connectionDetails.resourceName,
        apiKey: connectionDetails.apiKey,
        modelName: connectionDetails.modelName,
        tokensLimit: connectionDetails.tokensLimit,
      })
      setConnectionDetailsIdToUpdate(connectionDetails.id)
    }
  }, [connectionDetails, connectionDetailsIdToUpdate])

  const AssistantSettingsSchema = z.object({
    resourceName: z.string().refine(
      value => {
        return value.length > 0
      },
      { message: t('admin.azure_openAI_resource_name_input.errors.required') },
    ),
    apiKey: z.string().refine(
      value => {
        return value.length > 0
      },
      { message: t('admin.api_key_input.errors.required') },
    ),
    modelName: z.string().refine(
      value => {
        return value.length > 0
      },
      { message: t('admin.model_name_input.errors.required') },
    ),
    tokensLimit: z.number().refine(
      value => {
        return value > 0
      },
      { message: t('admin.conversation_char_limit_input.errors.required') },
    ),
  })
  const validationSchema = validationSchemaAdapter(AssistantSettingsSchema)

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      await validationSchema
        .validate(form)
        .then(async () => {
          try {
            if (connectionDetailsIdToUpdate) {
              await updateConnectionDetails({ connectionDetailsId: connectionDetailsIdToUpdate, ...form })
              onCloseAssistantSettings()
            } else {
              await createConnectionDetails(form)
              onCloseAssistantSettings()
            }
          } catch (e) {
            console.error(e)
          }
        })
        .catch(e => {
          for (const issue of e.inner) {
            setFormErrors(prevState => ({
              ...prevState,
              [issue.path]: { status: true, message: issue.message },
            }))
          }
        })
    },
    [
      form,
      validationSchema,
      createConnectionDetails,
      connectionDetailsIdToUpdate,
      updateConnectionDetails,
      onCloseAssistantSettings,
    ],
  )

  const handleChange = (value: string, key: string) => {
    setForm(prevState => ({
      ...prevState,
      [key]: value,
    }))
    setFormErrors(prevState => ({
      ...prevState,
      [key]: { status: false, message: '' },
    }))
  }

  if (isConnectionDetailsLoading) {
    return (
      <div className={styles.spinnerContainer}>
        <Flex justify="center" align="center" className={styles.spinnerContainer}>
          <WppSpinner size="m" />
        </Flex>
      </div>
    )
  }

  return (
    <>
      <Flex direction="column" justify="between" gap={16} className={styles.container}>
        <form id="assistantSettingsForm" onSubmit={handleSubmit}>
          <WppTypography tag="p" type="m-body">
            {t('admin.config_form_description')}
          </WppTypography>
          <div className={styles.inputsGroup}>
            <Flex
              className={(styles.inputWithTooltipWrapper, styles.inputWrapper)}
              justify="between"
              align="end"
              gap={20}
            >
              <WppInput
                name="azure_openAI_resource_name"
                labelConfig={{ text: t('admin.azure_openAI_resource_name_input.label') }}
                placeholder={t('admin.azure_openAI_resource_name_input.placeholder')}
                value={form.resourceName}
                onWppChange={e => handleChange(e.target.value, 'resourceName')}
                message={formErrors.resourceName.message}
                messageType={formErrors.resourceName.status ? 'error' : undefined}
                required
                className={styles.input}
              />
              <WppTooltip
                text={t('admin.azure_openAI_resource_name_input.tooltip')}
                config={{ placement: 'left' }}
                className={clsx(formErrors.resourceName.status ? styles.tooltipOnError : styles.tooltip)}
              >
                <WppIconInfo color={tooltipIconColor} />
              </WppTooltip>
            </Flex>
            <div className={styles.inputWrapper}>
              <WppInput
                name="api_key"
                labelConfig={{ text: t('admin.api_key_input.label') }}
                placeholder={t('admin.api_key_input.placeholder')}
                value={form.apiKey}
                onWppChange={e => handleChange(e.target.value, 'apiKey')}
                message={formErrors.apiKey.message}
                messageType={formErrors.apiKey.status ? 'error' : undefined}
                required
                className={(styles.input, styles.inputWithoutTooltip)}
              />
            </div>
            <Flex
              className={(styles.inputWithTooltipWrapper, styles.inputWrapper)}
              justify="between"
              align="end"
              gap={20}
            >
              <WppInput
                name="model_name"
                labelConfig={{ text: t('admin.model_name_input.label') }}
                placeholder={t('admin.model_name_input.placeholder')}
                value={form.modelName}
                onWppChange={e => handleChange(e.target.value, 'modelName')}
                message={formErrors.modelName.message}
                messageType={formErrors.modelName.status ? 'error' : undefined}
                required
                className={styles.input}
              />
              <WppTooltip
                text={t('admin.model_name_input.tooltip')}
                config={{ placement: 'left' }}
                className={clsx(formErrors.resourceName.status ? styles.tooltipOnError : styles.tooltip)}
              >
                <WppIconInfo color={tooltipIconColor} />
              </WppTooltip>
            </Flex>
            <Flex
              className={(styles.inputWithTooltipWrapper, styles.inputWrapper)}
              justify="between"
              align="end"
              gap={20}
            >
              <WppSelect
                name="conversation_character_limit"
                labelConfig={{ text: t('admin.conversation_char_limit_input.label') }}
                value={form.tokensLimit}
                required
                className={styles.input}
                onWppChange={e => handleChange(e.target.value, 'tokensLimit')}
                message={formErrors.tokensLimit.message}
                messageType={formErrors.tokensLimit.status ? 'error' : undefined}
              >
                {conversationCharacterLimitOptions.map((limitOption, index) => (
                  <WppListItem value={limitOption} key={index}>
                    <p slot="label">{limitOption}</p>
                  </WppListItem>
                ))}
              </WppSelect>
              <WppTooltip
                text={t('admin.conversation_char_limit_input.tooltip')}
                config={{ placement: 'left' }}
                className={clsx(formErrors.tokensLimit.status ? styles.tooltipOnError : styles.tooltip)}
              >
                <WppIconInfo color={tooltipIconColor} />
              </WppTooltip>
            </Flex>
          </div>
        </form>
        <div>
          <Flex justify="end" gap={12}>
            <WppButton variant="secondary" size="m" onClick={onCloseAssistantSettings}>
              {t('common.cancel')}
            </WppButton>
            <WppButton
              variant="primary"
              size="m"
              type="submit"
              form="assistantSettingsForm"
              loading={isCreateConnectionDetailsLoading || isUpdateConnectionDetailsLoading}
            >
              {t('common.save')}
            </WppButton>
          </Flex>
        </div>
      </Flex>
    </>
  )
}

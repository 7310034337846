// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tjt7y{--header-height: 68px}strong{font-weight:700}", "",{"version":3,"sources":["webpack://./src/app/App.module.scss"],"names":[],"mappings":"AAAA,OACE,qBAAA,CAIF,OACE,eAAA","sourcesContent":[".assistantContainer {\n  --header-height: 68px;\n}\n\n// explicit styling for markdown texts:\nstrong {\n  font-weight: 700;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"assistantContainer": "tjt7y"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GfKko{position:fixed;left:0;z-index:1;width:100%;margin-top:-8px;padding:0 24px 16px 24px;padding-bottom:16px;background-color:var(--wpp-grey-color-000)}.t5LMb{padding-left:4px;color:var(--wpp-grey-color-800)}.mybWR:hover .nlwea{color:var(--wpp-primary-color-500)}.mybWR:hover .t5LMb{color:var(--wpp-primary-color-500)}", "",{"version":3,"sources":["webpack://./src/components/assistant/assistantPopover/assistantContent/headerControls/HeaderControls.module.scss"],"names":[],"mappings":"AAAA,OACE,cAAA,CACA,MAAA,CACA,SAAA,CACA,UAAA,CACA,eAAA,CACA,wBAAA,CACA,mBAAA,CACA,0CAAA,CAGF,OACE,gBAAA,CACA,+BAAA,CAIA,oBACE,kCAAA,CAGF,oBACE,kCAAA","sourcesContent":[".headerControlsWrapper {\n  position: fixed;\n  left: 0;\n  z-index: 1;\n  width: 100%;\n  margin-top: -8px;\n  padding: 0 24px 16px 24px;\n  padding-bottom: 16px;\n  background-color: var(--wpp-grey-color-000);\n}\n\n.backButtonText {\n  padding-left: 4px;\n  color: var(--wpp-grey-color-800);\n}\n\n.backButton:hover {\n  & .backButtonIcon {\n    color: var(--wpp-primary-color-500);\n  }\n\n  & .backButtonText {\n    color: var(--wpp-primary-color-500);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerControlsWrapper": "GfKko",
	"backButtonText": "t5LMb",
	"backButton": "mybWR",
	"backButtonIcon": "nlwea"
};
export default ___CSS_LOADER_EXPORT___;

import { WppTypography } from '@platform-ui-kit/components-library-react'

import styles from 'components/assistant/chat/chatBubble/starfishAnswer/StarfishAnswer.module.scss'
import { Flex } from 'components/common/flex/Flex'
import { StarfishAnswerDto } from 'types/dto/ConversationDto'

interface Props {
  answer: StarfishAnswerDto
}

export const StarfishAnswer = ({ answer }: Props) => {
  const document = answer.content

  return (
    <a
      key={document?.title || 'Document'}
      href={`${document?.filePath}${document?.token}`}
      target="_blank"
      className={!document ? styles.disabled : undefined}
      rel="noreferrer"
    >
      <div className={styles.card}>
        <Flex gap={12}>
          <Flex>
            <img
              src={`${document.thumbnailPath ? document.thumbnailPath + document.token : document.previewPath}`}
              alt={document.title}
              className={styles.articleImg}
            />
          </Flex>
          <Flex direction="column" className={styles.articleNote} gap={20}>
            <Flex direction="column">
              <WppTypography type="2xs-strong" className={styles.documentType}>
                Case Study
              </WppTypography>
              <WppTypography type="s-midi">{document?.title}</WppTypography>
            </Flex>
          </Flex>
        </Flex>
      </div>
    </a>
  )
}

import { WppAvatar, WppTypography } from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'

import styles from 'components/assistant/chat/chatBubble/question/Question.module.scss'
import { Flex } from 'components/common/flex/Flex'

interface Props {
  text: string
}

export const Question = ({ text }: Props) => {
  const { osContext } = useOs()
  const { avatarUrl } = osContext.userDetails

  return (
    <Flex className={styles.chatBubbleQuestion} direction="row" wrap="nowrap" gap={12}>
      <WppAvatar size="s" src={avatarUrl ?? ''} className={styles.avatar} />
      <WppTypography tag="p" type="s-body" className={styles.text}>
        {text}
      </WppTypography>
    </Flex>
  )
}

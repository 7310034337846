import { OpenAIAnswer } from 'components/assistant/chat/chatBubble/openAIAnswer/OpenAIAnswer'
import { Question } from 'components/assistant/chat/chatBubble/question/Question'
import { StarfishAnswer } from 'components/assistant/chat/chatBubble/starfishAnswer/StarfishAnswer'
import { ConversationMessageDto } from 'types/dto/ConversationDto'

interface Props {
  message: ConversationMessageDto
}

export const ChatBubble = ({ message }: Props) => {
  const renderMessage = () => {
    switch (message.type) {
      case 'PROMPT':
        return <Question text={message.content} />
      case 'OPEN_API':
        return <OpenAIAnswer text={message.content} />
      case 'STARFISH':
        return <StarfishAnswer answer={message} />
    }
  }

  return <>{renderMessage()}</>
}

import { useQueryClient } from '@tanstack/react-query'
import { useOs } from '@wpp-open/react'

import { useCreateConnectionDetails } from 'api/assistantConfig/mutations/useCreateConnectionDetails'
import { useDeleteConnectionDetails } from 'api/assistantConfig/mutations/useDeleteConnectionDetails'
import { usePatchConnectionDetails } from 'api/assistantConfig/mutations/usePatchConnectionDetails'
import { useFetchConnectionDetails } from 'api/assistantConfig/queries/useFetchConnectionDetails'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { CreateConnectionDetailsDto } from 'types/dto/AssistantConfigDto'

type CreateConnectionDetailsArgs = Omit<CreateConnectionDetailsDto, 'tenantId'>
type UpdateConnectionDetailsArgs = CreateConnectionDetailsArgs & { connectionDetailsId: string }

interface DeleteConnectionDetailsArgs {
  connectionDetailsId: string
}

export const useAssistantConfigurator = () => {
  const { osContext } = useOs()
  const tenantId = osContext.tenant.id
  const queryClient = useQueryClient()

  const { mutateAsync: createConnectionDetailsMutation, isLoading: isCreateConnectionDetailsLoading } =
    useCreateConnectionDetails()
  const { mutateAsync: updateConnectionDetailsMutation, isLoading: isUpdateConnectionDetailsLoading } =
    usePatchConnectionDetails()
  const { mutateAsync: deleteConnectionDetailsMutation } = useDeleteConnectionDetails()

  const { data: connectionDetails, isLoading: isConnectionDetailsLoading } = useFetchConnectionDetails({
    params: { tenantId: tenantId },
  })

  const createConnectionDetails = async ({
    resourceName,
    apiKey,
    modelName,
    tokensLimit,
  }: CreateConnectionDetailsArgs) => {
    try {
      await createConnectionDetailsMutation({
        resourceName: resourceName,
        apiKey: apiKey,
        modelName: modelName,
        tokensLimit: tokensLimit,
        tenantId: tenantId,
      })
    } catch (err) {
      console.error(err)
    }
  }

  const updateConnectionDetails = async ({
    connectionDetailsId,
    resourceName,
    apiKey,
    modelName,
    tokensLimit,
  }: UpdateConnectionDetailsArgs) => {
    try {
      await updateConnectionDetailsMutation({
        connectionDetailsId: connectionDetailsId,
        resourceName: resourceName,
        apiKey: apiKey,
        modelName: modelName,
        tokensLimit: tokensLimit,
        tenantId: tenantId,
      })
    } catch (err) {
      console.error(err)
    }
  }

  const deleteConnectionDetails = async ({ connectionDetailsId }: DeleteConnectionDetailsArgs) => {
    try {
      await deleteConnectionDetailsMutation({ connectionDetailsId })
      queryClient.invalidateQueries([ApiQueryKeys.CONNECTION_DETAILS])
    } catch (err) {
      console.error(err)
    }
  }

  return {
    connectionDetails,
    isConnectionDetailsLoading,
    createConnectionDetails,
    isCreateConnectionDetailsLoading,
    updateConnectionDetails,
    isUpdateConnectionDetailsLoading,
    deleteConnectionDetails,
  }
}
